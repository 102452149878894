*{
  margin: 0;
  padding: 0;
}
body {
  overflow-x: hidden;
}

section {
  width: 80vw;
  margin: 0 auto;
}

section h2 {
  font-family: 'Century Gothic';
  margin-bottom: 1rem;
}

.section-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
  gap: 10px;
}

.full {
  grid-column-start: 1;
  grid-column-end: 3;
}

.left, .right {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.left img, .right img {
  width: 100%;
  object-fit: contain;
}

.section-content button {
  background-color: darkcyan;
  border: none;
  font-size: 1.3rem;
  margin: 10px 20px;
  padding: 5px 10px;
  border-radius: 20px;
}

.Header {
  display: flex;
  width: 100vw;
  padding: 10px 20px;
  height: 5vh;
  background-color: beige;
  margin-bottom: 20px;
}

.Header h1{
  font-size: 2rem;
  font-family: 'Century Gothic';
}

.node {
  width: auto;
  text-align: center;
  border: 1px solid black;
  height: fit-content;
}
.node p {
  margin-bottom: 20px;
  font-size: 1.5rem;
}

.node.remove{
  background-color: red;
}
.node.added {
  background-color: greenyellow;
}
.node.replaced-old {
  background-color: darkcyan;
}
.node.replaced {
  background-color: lightblue;
}

.children {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.input {
  display: grid;
  background-color: darkcyan;
  grid-template-columns: 1fr 1fr;
}

.controls {
  display: flex;
  justify-content: center ;
  padding: 20px;
}

.controls button{
  border: none;
  background-color: cyan;
  padding: 10px 20px;
  border-radius: 10px;
}

.legend{
  display: flex;
}
.legend div{
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  aspect-ratio: 1/1;
}
.legend div:nth-child(1) {
  background-color: lightblue;
}
.legend div:nth-child(2) {
  background-color: darkcyan;
}
.legend div:nth-child(3) {
  background-color: red;
}
.legend div:nth-child(4) {
  background-color: greenyellow;
}

.hover-show .show-on-hover{
  display: none;
}

text.hover-show:hover .show-on-hover{
  display: unset;
}

.survey-container {
  width: 100vw;
  height: 80vh;
  font-family: "Century Gothic";
}


.chart_container {
  font-family: "Century Gothic";
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.survey-container .question-container {
  display: grid;
  grid-template-columns: 4fr 2fr;
  width: 100%;
  height: 100%;
}

.survey-container .question-container .left-part{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
}

.survey-container h3 {
  font-size: 2rem;
  text-align: left;
  font-family: "Century Gothic";
}

.survey-container .survey-node-legend {
  padding: 5px 20px;
  display: flex;
}
.survey-container .question-container .left-part img {
  max-width: 100%;
}
.survey-container .question-container .left-part h2 {
  font-size: 2rem;
  font-family: "Century Gothic";
}
.survey-container .question-container .left-part span.code {
  background-color: lightgray;
  padding: 0px 5px;
}
.survey-container .question-container .left-part p {
  margin-bottom: 10px;
  font-size: 1rem;
  font-family: "Century Gothic";
}
.survey-container .question-container .right-part {
  border-left: 1px solid black;
  padding: 20px;
}
.survey-container .question-container .right-part form{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: center;
}

form p, form label{
  font-family: "Century Gothic";
  font-size: 1.5rem;
}
form input{
  font-size: 1.5rem;
}
form input[type="submit"] {
  cursor: pointer;
  border-radius: 10px;
  border: none;
  padding: 10px 20px;
  background-color: lightblue;
}
form input[type="submit"]:hover {
  background-color: rgb(114, 138, 146);
}